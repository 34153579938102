import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface KeywordType {
  label: string,
  state: boolean
}

interface Filterype {
  category: string,
  keywords: KeywordType[],
  filter: [string?]
}

const keywordInitialState = [
  { label: "farm", state: false },
  { label: "vehicle", state: false },
  { label: "connectivity", state: false }
]

const initialState = {
    category: 'All',
    filter: [],
    keywords: [...keywordInitialState]
  } as Filterype

const filterSlice = createSlice({
  name: 'filterReports',
  initialState,
  reducers: {
    setCategory(state, action: PayloadAction<string>) {
      state.category = action.payload
    },
    addFilter(state, action: PayloadAction<string>) {
      state.filter.push(action.payload)
    },
    removeFilter(state, action: PayloadAction<string | undefined>) {
      const index = state.filter?.findIndex(r => r === action.payload)
      
      if (index !== -1) {
        state.filter.splice(index, 1) // remove element
      }
    },
    updateKeywordState(state, action: PayloadAction<KeywordType>) {
      const index = state.keywords?.findIndex(r => r.label === action.payload.label)
      state.keywords[index].state = action.payload.state
    },
    resetFilter(state) {
      state.category = 'All'
      state.filter = []
      state.keywords = keywordInitialState
    }
  },
})

export const { 
  setCategory, 
  addFilter, 
  removeFilter, 
  updateKeywordState, 
  // removeKeyword,
  resetFilter } = filterSlice.actions
export default filterSlice.reducer
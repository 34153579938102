import Paper from '@mui/material/Paper'
import React from 'react'

const RequestReport = () => {
  return (
    <Paper>
        <iframe title='request report' src='https://apps.powerapps.com/play/e/default-79310fb0-d39b-486b-b77b-25f3e0c82a0e/a/33054b77-3375-4762-b4ca-48390757c4dd?tenantId=79310fb0-d39b-486b-b77b-25f3e0c82a0e'>
        </iframe>
    </Paper>
  )
}

export default RequestReport
import { Key } from 'react'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface TabItem {
  label: string,
  reportId: string,
  embedUrl: string,
  embeddedToken: string,
  // closable: boolean,
  // hide: boolean,
  component: string
}

interface TabsType {
  activeTabIndex: Number | Key | null | undefined,
  activeReportId: Key | null | undefined,
  activeReportCatalog: Boolean,
  tabs: TabItem[]
}

const initialState = {
  activeTabIndex: 0,
  activeReportId: 'home',
  activeReportCatalog: true,
  tabs: []
  } as TabsType

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveReportId(state, action: PayloadAction<Key | null | undefined>) {
      state.activeReportId = action.payload
    },
    setActiveTabIndex(state, action: PayloadAction<Number>) {
      state.activeTabIndex = action.payload
    },
    setMenu(state, action: PayloadAction<TabItem[]>) {
      state.tabs = action.payload
    },
    setActiveReportCatalog(state, action: PayloadAction<Boolean>) {
      state.activeReportCatalog = action.payload
    },
    addTab(state, action: PayloadAction<TabItem>) {
      const index = state.tabs.findIndex(r => r.reportId === action.payload.reportId)
      state.activeReportCatalog = false
      
      if (index === -1) { // not on list yet
        state.tabs.push(action.payload)
        state.activeTabIndex = state.tabs.length-1
      } else {
        state.activeTabIndex = index
      }
      state.activeReportId = action.payload.reportId
    },
    removeTab(state, action: PayloadAction<string>) {
      const index = state.tabs.findIndex(r => r.reportId === action.payload)
      const activeIndex = state.tabs.findIndex(r => r.reportId === state.activeReportId)

      if (state.tabs.length === 1)
        state.activeReportCatalog = true

      if (index !== -1) {
        state.tabs.splice(index, 1)
        if (index !== activeIndex) {
          if (index < activeIndex) {
            state.activeTabIndex = activeIndex-1
            state.activeReportId = state.tabs[activeIndex-1].reportId
          } else {
            // state.activeTabIndex = index-1
            // state.activeReportId = state.tabs[index-1].reportId
          }
        } else {
          if (state.tabs.length > 1) {
            state.activeTabIndex = activeIndex-1
            state.activeReportId = state.tabs[activeIndex-1].reportId
          } else if (state.tabs.length === 1) {
            state.activeTabIndex = 0
            state.activeReportId = state.tabs[0].reportId
          }
        }
      }
    }
  },
})

export const { setActiveTabIndex, setActiveReportId, setMenu, addTab, removeTab, setActiveReportCatalog } = tabsSlice.actions
export default tabsSlice.reducer
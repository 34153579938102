import React from 'react'
import Paper from '@mui/material/Paper'
import GoogleMap from 'google-map-react'
import Marker from './Marker'

interface ReportProps {
  workspace_id: string,
  company_id: string,
  report_id: string
}

interface MapType {
  vehicleLocation: { 
    response_data: {
      "data": string | number | ReportProps,
      "data_type": string,
      "data_unit": string,
      "machine_model": string,
      "machine_vin": string,
      "metric": string,
      "metric_type": string,
      "time": string
    },
    response_type: string
  }[]
}

const Map:React.FC<MapType> = (props) => {
  const vehicleLocations = props.vehicleLocation.map((record) => {
    const coord = record.response_data.data.toString().split(',')
    return {
      lat: parseFloat(coord[0]),
      lng: parseFloat(coord[1]),
      machine_model: record.response_data.machine_model,
      machine_vin: record.response_data.machine_vin,
    }
  })

  const defaultProps = {
    center: {
      lat: vehicleLocations[0].lat,
      lng: vehicleLocations[0].lng
    },
    zoom: 12
  };

  const distanceToMouse = (pt: any, mp: any) => {
    if (pt && mp) {
      // return distance between the marker and mouse pointer
      return Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
      );
    }
  }

  return (
    <Paper sx={{ height: '45vh' }}>
      <GoogleMap
        bootstrapURLKeys={{ 
          key: "AIzaSyCr1iOytIUw9mE9Enxr2C60nYglNMMxk4o",
          libraries: ['places', 'geometry', 'drawing', 'visualization']
        }}
        options={{ mapTypeControl: true,  mapTypeId: 'hybrid', }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        distanceToMouse={distanceToMouse}
      >
        { vehicleLocations.map(i => <Marker
          key={i.machine_vin}
          lat={i.lat}
          lng={i.lng}
          machine_vin={i.machine_vin}
          machine_model={i.machine_model}
        /> )}
      </GoogleMap>
    </Paper>
  )
}

export default Map
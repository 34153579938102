import { createSlice } from '@reduxjs/toolkit'

interface ChatbotState {
  email: string
}

const initialState = { 
    email: "HEATHADKISSON@GMAIL.COM"
} as ChatbotState

const chatbotSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setEmail(state, action) {
        state.email = action.payload
    },
   }
})

export const { setEmail  } = chatbotSlice.actions
export default chatbotSlice.reducer
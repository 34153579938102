import './App.css';
import React from 'react';
import { Routes, Route, Outlet, Navigate } from "react-router-dom"
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Box from '@mui/material/Box';
import Container from './pages/Container'
import Home from './pages/Home'
import Login from './pages/Login'
import Report from './pages/Report';
import RequestReport from './pages/RequestReport'
import ChatPage from './pages/ChatPage'
import ChatEmail from './pages/ChatbotEmail'
import { loginRequest } from './authConfig'
import MSALErrorHandler from './components/MSALErrorHandler'

interface Props {
  children: JSX.Element
}

const ProtectedPage: React.FC<Props> = ({ children }) => (<MsalAuthenticationTemplate
  interactionType={InteractionType.Popup}
  authenticationRequest={loginRequest}
  errorComponent={Login}
> {children}
</MsalAuthenticationTemplate>)

// console.log(window.URL)

const App = () => (
  <Box className="App">
    <Routes>
      <Route path="/" element={<Login />}  />
      <Route path="/auth" element={<ProtectedPage><Container /></ProtectedPage>}>
        <Route path="/auth/index" element={<Home />} />
        <Route path="/auth/request-report" element={<RequestReport />} />
        <Route path="/auth/reports" element={<Report />} />
        <Route path="/auth/email" element={<ChatEmail />} />
        <Route path="/auth/chat" element={<ChatPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/auth/index" />} />
    </Routes>
    <Outlet />
  </Box>
);

export default App;

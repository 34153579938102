import React from 'react'
// import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

const ReportNotFound = () => {
  // const navigate = useNavigate()

  return (
    <Box sx={{ py: 2 }}>
      <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
        <Typography variant="subtitle2" component="h2" >
          Not finding the report you need?
        </Typography>
        <Button variant="outlined" onClick={(e) => {
            window.open('https://apps.powerapps.com/play/e/default-79310fb0-d39b-486b-b77b-25f3e0c82a0e/a/33054b77-3375-4762-b4ca-48390757c4dd?tenantId=79310fb0-d39b-486b-b77b-25f3e0c82a0e', '_blank');
        }}>
          Request New Report
        </Button>
      </Stack>
    </Box>
  )
}

export default ReportNotFound
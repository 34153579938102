import React from 'react'
import Paper from '@mui/material/Paper'
import ChatPBIReport from './ChatPBIReport'
import { useGetReportURLQuery } from '../../store/services/reportApi'

interface ReportProps {
  workspaceId: string,
  reportId: string,
  companyId: string
}

interface PBIReportType {
  token: string,
  embeddedURL: string
}

interface PBIReportResultType {
  data: PBIReportType,
  error: any,
  isLoading: boolean
}

const Report:React.FC<ReportProps> = ({ workspaceId, reportId, companyId }) => {
  const { data, isLoading } = useGetReportURLQuery<PBIReportResultType>({ workspaceId, reportId, companyId })

  return (
    <Paper>
      { data && !isLoading && <ChatPBIReport  
        reportId={reportId}
        token={data.token}
        embedUrl={data?.embeddedURL} />
      }
    </Paper>
  )
}

export default Report
import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ChatResponseAnswer } from '../../pages/ChatPage'

interface Column {
    id: 'vin' | 'model' | 'time' | 'value';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any) => string;
}

interface TabularDataProps {
    data: ChatResponseAnswer[]
}

const TabularData: React.FC<TabularDataProps> = ({ data: dataProps }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    let valueHeading = dataProps ? dataProps[0].response_data.metric : ''
    valueHeading = valueHeading.replace('_', ' ')
    valueHeading = valueHeading.charAt(0).toUpperCase() + valueHeading.slice(1)

    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    const columns: readonly Column[] = [
        { id: 'vin', label: 'VIN', minWidth: 200 },
        { id: 'model', label: 'Model', minWidth: 200 },
        {
            id: 'time',
            label: 'Time',
            minWidth: 200,
            align: 'right'
        },
        {
            id: 'value',
            label: valueHeading,
            minWidth: 170,
            align: 'right'
        }
    ];

    interface Data {
        vin: string;
        model: string;
        time: string;
        value: number | string;
    }

    function createData(
        vin: string,
        model: string,
        time: string,
        value: number | string,
        metric_type?: string,
        data_unit?: string
    ): Data {
        let _time = time !== "" ? new Date(time+"T12:00:00").toDateString() : ""
        let ltime = new Date(time).toDateString()

        let _value = value + ' ' + data_unit

        if (metric_type == 'latest' && _time) {
            _time = ltime + " " + new Date(time).toLocaleTimeString()
        }

        return { vin, model, time: _time, value: _value };
    }

    const rows: Data[] = dataProps.map(r =>
        createData(r.response_data.machine_vin, 
            r.response_data.machine_model, r.response_data.time, 
            r.response_data.data, r.response_data.metric_type, r.response_data.data_unit))
    // [
    //     // createData('India', 'IN', 1324171354, 3287263),
    //     // createData('China', 'CN', 1403500365, 9596961)
    // ];
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, cindex) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={cindex}>
                                        {columns.map((column, kindex) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={kindex} align={column.align}>
                                                    {column.format ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default TabularData

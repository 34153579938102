import React from 'react'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Send from '@mui/icons-material/Send';
import { setEmail } from '../../store/features/chatbotSlice'
import { RootState } from '../../store'

const Email = () => {
  const dispatch = useDispatch()
  const email = useSelector((state: RootState) => state.chatbot.email)
  const navigate = useNavigate()

  const handleClickNext = () => {
    // 
  }

  return (
      <Grid 
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}>
          <Grid item xs={10}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput 
                label="Email" 
                fullWidth
                sx={{ minWidth: '50vw' }}
                inputProps={{
                  'aria-label': 'email',
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => navigate('/auth/chat')}
                      edge="end"
                    >
                      <Send color='primary' />
                    </IconButton>
                  </InputAdornment>
                }
                value={email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
                />
              </FormControl>
          </Grid>
      </Grid>
  )
}

export default Email
import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMsal } from "@azure/msal-react";
import { RootState } from '../store';
import { TabPanel } from '../components/TabPanel';
import { TabItem } from '../store/features/tabsSlice';
import { setToken } from '../store/features/systemSlice'
import Grid from '@mui/material/Grid';
import ReportCatalog from '../components/ReportCatalog'
import PBIReport from '../components/PBIReport'
import LeftPane from '../components/left-pane'
import { useGetCompanyReportsQuery, ReportResultType, ReportType } from '../store/services/reportApi'
import { useIdToken } from '../hooks/useLogin';

const REFRESH_TOKEN_EVERY_X_MINUTES = 40 * 1000 * 60

interface LoadComponentProps {
  tab: TabItem
}

const LoadComponent: React.FC<LoadComponentProps> = ({ tab }: LoadComponentProps) => {
  if (tab.component === "home") return <></>
  return <PBIReport embedUrl={tab.embedUrl} reportId={tab.reportId} embeddedToken={tab.embeddedToken} />
}

const Home = () => {
  const [reportList, setReportList] = useState<ReportType[]>([])
  const [reportData, setReportData] = useState<ReportType[]>([])
  const [skip, setSkip] = useState(true)
  // const idToken = useSelector((state: RootState) => state.auth.idToken)
  const reportTabs = useSelector((state: RootState) => state.reportTabs.tabs)
  const reportCategory = useSelector((state: RootState) => state.filterReports.category)
  const filters = useSelector((state: RootState) => state.filterReports.filter)
  const keywords = useSelector((state: RootState) => state.filterReports.keywords)
  const activeReportId = useSelector((state: RootState) => state.reportTabs.activeReportId)
  const activeReportCatalog = useSelector((state: RootState) => state.reportTabs.activeReportCatalog)
  const { data, isLoading, refetch } = useGetCompanyReportsQuery<ReportResultType>(undefined, { skip })
  const { getToken } = useIdToken()
  const dispatch = useDispatch()
  const { instance, inProgress } = useMsal();
  const memoizeGetToken = useMemo(() => getToken, [getToken])

  // useEffect(() => {
  //   console.log('id token ', inProgress, instance)
  //   if(inProgress === 'none' && instance)
  //     setSkip(!inProgress)
  // }, [inProgress, instance])

  useEffect(() => {
    memoizeGetToken().then(t => setSkip(false))

    const timer = setInterval(async () => {
      await memoizeGetToken()
      refetch()
    }, REFRESH_TOKEN_EVERY_X_MINUTES);

    return () => {
      clearInterval(timer);
    }
  }, [refetch, memoizeGetToken]);

  useEffect(() => {
    if (data) {
      dispatch(setToken(data.embedToken))

      setReportList(data.reports)
      setReportData(data.reports)
    }
  }, [data, dispatch])

  useEffect(() => {
    if (reportList.length) {
      let _filteredReports: ReportType[] = []

      reportList.forEach(r => {
        const _category = (!r.category) ? '' : r.category.toLowerCase()

        if (reportCategory && reportCategory !== "all") {
          if (r.category?.includes(reportCategory)
            && _filteredReports.findIndex(rl => rl.reportId === r.reportId) === -1)
            _filteredReports.push(r)
        }

        // search categories
        filters.forEach(f => {
          const _filter = (!f) ? '' : f.toLowerCase()

          if ((r.alias.toLowerCase().includes(_filter)
            || r.purpose?.toLowerCase().includes(_filter)
            || r.searchKeywords.toString().toLowerCase().includes(_filter)
            || _category.includes(_filter))
            && _filteredReports.findIndex(rl => rl.reportId === r.reportId) === -1) {
            _filteredReports.push(r)
          }
        })

        // search keywords in category
        keywords.filter(r => r.state).forEach(k => {
          const keyword = (!k) ? '' : k.label.toLowerCase()

          if (
            (r.alias.toLowerCase().includes(keyword)
              || _category.includes(keyword)
            )
            && _filteredReports.findIndex(rl => rl.reportId === r.reportId) === -1) {
            _filteredReports.push(r)
          }
        })
      })

      if (filters.length === 0 && keywords.filter(r => r.state).length === 0 && (!reportCategory || reportCategory === "All")) {
        setReportData(reportList)
      }
      else {
        setReportData(_filteredReports)
      }
    }
  }, [reportCategory, filters, keywords, reportList])

  return (
    <Grid container spacing={0} sx={{ height: 'calc(100vh - 10em)' }}>
      <>
        <Grid item xs={2.5} sx={{ mt: 1, p: 0, display: activeReportCatalog ? 'block' : 'none' }} >
          <LeftPane />
        </Grid>
        <Grid
          item
          xs={9.5}
          sx={{
            p: 1,
            mt: 1,
            display: activeReportCatalog ? 'block' : 'none'
          }}>
          <ReportCatalog reportData={reportData} isLoading={isLoading} />
        </Grid>
      </>
      <Grid
        item
        xs={12}
        sx={{
          p: 1,
          mt: 1,
          display: !activeReportCatalog ? 'block' : 'none'
        }}>
        {reportTabs.map(tab => (
          <TabPanel key={tab.reportId} index={tab.reportId} value={activeReportId} >
            <LoadComponent tab={tab} />
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  )
}

export default Home
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser'
import { CssBaseline, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Header from '../components/Header'
import SignInButton from '../components/SigninButton';
import '../style/login.css'

const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate()
  const { inProgress } = useMsal();

  useEffect(() => {
    console.log(inProgress, isAuthenticated)
    if(isAuthenticated && inProgress === InteractionStatus.None) {
      navigate('/auth/index')
    }
  }, [isAuthenticated, inProgress, navigate])

  if(!isAuthenticated && inProgress === InteractionStatus.None) {
    return (
    <Box sx={{ flexGrow: 1, height: '100vh' }} className="login-canvas">
      <CssBaseline />
      <Paper elevation={3}>
        <Header />
      </Paper>
      <Box sx={{ flexGrow: 1, height: 'calc(100vh - 7em)' }} 
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center" >
        <SignInButton />
      </Box>
    </Box>
    )
  }
  return <></>
}

export default Login
const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID ?? ''
const REACT_APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL ?? ''
const REACT_APP_AUTHORITY = process.env.REACT_APP_AUTHORITY ?? ''

export const msalConfig = {
    auth: {
        //// dev: df4b31c5-1b11-4395-9a91-dc6b82232a48
        // prod: 1cb8cbe6-3545-4e6d-8f19-87f653ec2970
      clientId: REACT_APP_CLIENT_ID, //1cb8cbe6-3545-4e6d-8f19-87f653ec2970 df4b31c5-1b11-4395-9a91-dc6b82232a48
      authority: REACT_APP_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: REACT_APP_REDIRECT_URL,
      // navigateToLoginRequestUrl: false
    },
    // cache: {
    //   cacheLocation: "sessionStorage", // This configures where your cache will be stored
    //   storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    // },
    // system: {
    //   windowHashTimeout: 10000, 
    //   iframeHashTimeout: 10000, // Applies just to silent calls - In milliseconds
    //   loadFrameTimeout: 10000 // Applies to both silent and popup calls - In milliseconds
    // }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = { 
    scopes: ["openid", "profile"], 
    forceRefresh: true 
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
//   export const graphConfig = {
//       graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
//   };
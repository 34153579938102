import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import * as pbi from "powerbi-client";
import Box from '@mui/material/Box';
import { PowerBIEmbed } from 'powerbi-client-react';
import { makeStyles } from '@mui/styles';
import { /*GeneratePDFParamType,*/ UserReportLogType, useReportLogQuery } from '../store/services/reportApi';

const useStyles = makeStyles(() => ({
  reportStyleClass: {
    height: 'calc(100vh - 7rem)'
  }
}))

const Report = () => {
  const [skip, setSkip] = useState(true)
  const [reportLoadingTime, setReportLoadingTime] = useState(0)
  const token = useSelector((state: RootState) => state.system.token)
  const reportId = useSelector((state: RootState) => state.system.selectedReportId)
  const userId = useSelector((state: RootState) => state.auth.userId)
  const embedUrl = useSelector((state: RootState) => state.system.selectedReportEmbedURL)
  useReportLogQuery<UserReportLogType>({
    ReportId: reportId,
    UserId: userId,
    ReportLoadingTime: reportLoadingTime
  }, { skip })

  const initialTime = new Date()

  const [_report, setReport] = useState<any>()
  const styles = useStyles()

  useEffect(() => {
    const repaint = async () => {
      if( _report ) {
        await _report.setAccessToken(token)
      }
    }

    repaint()
  }, [token, _report])

  return (
    <Box>
      {/* <div className="loading">
        Loading...<br/> */}
        {/* <img src="../assets/CNHBreakingNewGround.png" width="299" height="81" /> */}
      {/* </div> */}
      { !!reportId && <PowerBIEmbed
        embedConfig = {{
          type: 'report',   // Supported types: report, dashboard, tile, visual and qna
          id: reportId,
          embedUrl,
          accessToken: token,
          tokenType: pbi.models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            background: pbi.models.BackgroundType.Transparent,
          }
        }}

        eventHandlers = { 
          new Map([

            ['loaded', function () {
              const MILLI_CONSTANT = 1000
              const loadingTime = ((new Date().getTime()) - initialTime.getTime())/MILLI_CONSTANT
              setReportLoadingTime(loadingTime)
              if (skip) { // skip true
                setSkip(false)
              }
            }],
            ['rendered', function () { setSkip(true) }],
            ['error', function (event) {}]
          ])
        }
          
        cssClassName = { styles.reportStyleClass }

        getEmbeddedComponent = { (embeddedReport: any) => {
          setReport(embeddedReport);
        }}
      />}
    </Box>
  )
}

export default Report
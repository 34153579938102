import React from 'react';
// import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { setActiveReportId, setActiveTabIndex, removeTab, setActiveReportCatalog } from '../store/features/tabsSlice'
import Stack from '@mui/material/Stack'

interface StyledTabsProps {
  children?: React.ReactNode;
  value: Number | React.Key | null | undefined;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  variant?: "standard" | "scrollable" | "fullWidth"
  scrollButtons?: boolean | "auto"
  displayIndicator?: string
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    variant={props.variant}
    scrollButtons={props.scrollButtons}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    sx={{ paddingTop: '20px', paddingBottom: '0px' }}
  />
))({
  '&.MuiTabs-root': {
    paddingTop: '16px',
    paddingBottom: '0px',
    color: 'red'
  },
  '& .Mui-selected': {
    borderBottom: 0,
    backgroundColor: '#fff'
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

interface StyledTabProps {
  label: string,
  // closable?: boolean,
  index: string,
  handleRemoveTab?: (event: React.MouseEvent, i: string) => void,
  // display: string,
  isTabSelected: boolean
}

const StyledTab = styled(({ isTabSelected, index, handleRemoveTab=()=>{}, ...props }: StyledTabProps) => {
  return (
  <Tab 
    disableRipple {...props} 
    id={ 'tab-' + index } 
    aria-controls={'tabpanel-' + index} 
    label={
    <div>
      {props.label.toUpperCase()}
      <IconButton size="small">
        <ClearIcon onClick={(e) => handleRemoveTab(e, index)}/>
      </IconButton>
    </div>
  } />
)})(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  maxWidth: '256px',
  boxSizing: 'border-box',
  border: '1px solid #616161',
  borderRadius: '11px 11px 0px 0px',
  height: '50px',
  paddingBottom: '0px',
  paddingTop: '0px',
}));

export default function RSTabs() {
  let del = false
  const reportTabs = useSelector((state:RootState) => state.reportTabs.tabs)
  const activeTabIndex = useSelector((state:RootState) => state.reportTabs.activeTabIndex)
  const activeReportCatalog = useSelector((state:RootState) => state.reportTabs.activeReportCatalog)

  const dispatch = useDispatch()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setActiveReportCatalog(false))
    if (del) {
      del = false
      return;
    } else {
      dispatch(setActiveReportId(reportTabs[newValue].reportId))
      dispatch(setActiveTabIndex(newValue))
    }
  };

  const handleRemoveTab = (event: React.MouseEvent, i: string) => {
    dispatch(removeTab(i))
    del = true
  }

  return (
    <Stack direction="row" sx={{ flexGrow: 1, backgroundColor: '#E8EAF6', height: '66px' }}>
      <StyledTabs
          value={activeTabIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ py: 1 }}
        >
          {
            reportTabs.map(tab => (
              <StyledTab 
                key={tab.reportId} 
                handleRemoveTab={handleRemoveTab} 
                index={tab.reportId} 
                label={tab.label.slice(0,18)}
                isTabSelected={!activeReportCatalog}
              />
            ))
          }
      </StyledTabs>
    </Stack>
  );
}
// closable={tab.closable} 
                
// display={tab.hide ? 'none' : 'inline-flex'}
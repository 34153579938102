import {
    createApi,
    fetchBaseQuery
  } from "@reduxjs/toolkit/query/react";
  import { RootState } from '../'

const APIM_SUBSCRIPTION_VALUE = process.env.REACT_APP_APIM_SUBSCRIPTION_VALUE
const BASE_URL = process.env.REACT_APP_API_URL

// import { Report } from '../features/menu/menuSlice'

export interface ReportType {
    reportId: string,
    alias: string,
    embedUrl: string,
    token: string,
    category?: string,
    purpose?: string,
    searchKeywords: [string]
}

export interface CategoryType {
    label: string,
    icon: string,
    path: string,
    reports: ReportType[]
}

export interface ReportResultDataType {
    reports: ReportType[],
    embedToken: string
}

export interface ReportResultType {
    data: ReportResultDataType,
    error: any,
    isLoading: boolean
}

export interface GeneratePDFParamType {
    ExportType: string,
    ReportId: string | undefined,
    BookmarkState: string
}

export interface UserReportLogType {
    ReportId: string | undefined,
    UserId: string,
    ReportLoadingTime: number
}

interface ReportParamType {
    workspaceId: string,
    reportId: string,
    companyId: string
}

export const reportApi = createApi({
    reducerPath: "reportApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: async (headers, { getState }) => {
            const state = getState() as RootState
            
            headers.set('Content-Type', `application/json`)
            headers.set('Ocp-Apim-Subscription-Key', `${APIM_SUBSCRIPTION_VALUE}`)
            headers.set('Authorization', `Bearer ${state.auth.idToken}`)
            return headers
        }
    }),
    tagTypes: ["Reports"],
    endpoints: (builder) => ({
        getCompanyReports: builder.query({
            query: () => ({
                url: '/InternalReport/get-data-engineering-reports' // `/InternalReport`
            })
        }),
        getPdf: builder.mutation({
            query: (report: GeneratePDFParamType) => ({
                url: `/InternalReport/exportToFile`,
                method: 'POST',
                body: report
            })
        }),
        reportLog: builder.query({
            query: (params: UserReportLogType) => ({
                url: `/InternalReport/reportLog`,
                params
            })
        }),
        getReportURL: builder.query({
            query: (params: ReportParamType) => ({
                url: `/DealerPortal`,
                params
            })
        })
    })
})

// src/services/taskApi.js
export const {
    useGetCompanyReportsQuery,
    useReportLogQuery,
    useGetReportURLQuery
  } = reportApi;
import React from 'react'
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import SearchField from './SearchField';
// import CommonKeywords from './CommonKeywords'
import CategoryFilter from './CategoryFilter';
import ResetFilters from './ResetFilters';

const LeftPane = () => {
  return (
    <Paper sx={{ height: '81vh', p: '8px 12px 12px 24px', bgcolor: 'secondary.light', overflowY: 'auto' }} elevation={0} >
      <Stack direction="column" spacing={1}>
        <SearchField />
        {/* <CommonKeywords /> */}
        <CategoryFilter />
        <ResetFilters />
      </Stack>
    </Paper>
  )
}

export default LeftPane
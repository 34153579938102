import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'

interface FaultAlarmType {
    model: string,
    vin: string,
    data: string,
    time: string,
    metric_type: string
}

const useStyles = makeStyles({
  boxLocation: {
    textDecoration: 'none'
  }
})

const FaultAlarm:React.FC<FaultAlarmType> = (props) => {
  const { model, vin, data, time, metric_type } = props
  let _time = new Date(time).toDateString()

  if(metric_type == 'latest')
    _time = _time + " " + new Date(time).toLocaleTimeString()


  return (
    <Box>
        <div>VIN: {vin}</div>
        <div>Model: {model}</div>
        <div>Time: {_time}</div>
        <div>Message: {data}</div><br/>
    </Box>
  )
}

export default FaultAlarm
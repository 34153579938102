import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { RootState } from '../store';
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress';

interface GeneratePdfProp {
  report: any,
  reportId: any
}

const GeneratePdf: React.FC<GeneratePdfProp> = ({ report, reportId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const idtoken = useSelector((state: RootState) => state.auth.idToken)
  const API_URL = process.env.REACT_APP_API_URL
  const APIM_SubscriptionValue = process.env.REACT_APP_APIM_SUBSCRIPTION_VALUE
  const url = `${API_URL}/InternalReport/exportToFile`

  const handleClick = async () => {
    setIsLoading(true)
    let bookmarkState = await report.bookmarksManager.capture({
      allPages: true,
      personalizeVisuals: true
    });

    const body = {
      "ExportType": "pdf",
      "filter": "",
      reportId,
      "WorkspaceId": "ce18987b-1d6f-40c1-81d1-1479278f259d",
      //   "CompanyId": selectedCompanyId,
      "BookmarkState": bookmarkState.state,
      //   "Language": language
    }

    const rawResponse = fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        "Ocp-Apim-Subscription-Key": APIM_SubscriptionValue || "",
        'Authorization': `Bearer ${idtoken}`
      },
      body: JSON.stringify(body)
    });

    // rawResponse.then(r => console.log(r))

    rawResponse.then(response => response.blob())
      .then(blob => downloadFile(blob))
  }

  const downloadFile = (blob: any) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.target = '_blank'
    a.click()
    setIsLoading(false)
  }

  if (!isLoading) return (<Fab color="primary" aria-label="add"
    sx={{
      margin: 0,
      top: '21vh',
      right: 20,
      bottom: 'auto',
      left: 'auto',
      position: 'fixed'
    }} onClick={handleClick} >
    <PictureAsPdfIcon />
  </Fab>)
  else return (<CircularProgress sx={{
    margin: 0,
    top: '21vh',
    right: 20,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
  }} />)
}

export default GeneratePdf
import React from 'react';
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { ReportType } from '../store/services/reportApi'
import { addTab } from '../store/features/tabsSlice'
import ReportNotFound from './ReportNotFound';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#BDBDBD', //theme.palette.common.black
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: '#E8EAF6',
    cursor: 'pointer'
  }
}));

interface ReportCatalogParams {
  reportData: ReportType[],
  isLoading: boolean
}

const ReportCatalog: React.FC<ReportCatalogParams> = ({ reportData, isLoading }: ReportCatalogParams) => {
  const dispatch = useDispatch()

  const newTab = (report: ReportType) => dispatch(addTab({
      label: report.alias,
      reportId: report.reportId,
      embedUrl: report.embedUrl,
      embeddedToken: report.token,
      component: 'report'
    }))

  const noDataRow = reportData.length === 0 ? (<StyledTableRow>
      <StyledTableCell component="td" scope="row" colSpan={3}>
        No results found
      </StyledTableCell>
    </StyledTableRow>) : (<></>)
  
  return (
    !isLoading && reportData ? (
    <TableContainer sx={{ maxHeight: 'calc(78vh)' }}>
      {/* , width: "calc(100vw - 17em)" */}
      <Table stickyHeader sx={{ flex: 1 }} aria-label="Report Catalog">
        <TableHead>
          <TableRow>
            <StyledTableCell>Report Name</StyledTableCell>
            <StyledTableCell align="left" width={200}>Category</StyledTableCell>
            <StyledTableCell align="left">Purpose</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { noDataRow }
          {
            reportData.map((report) => (
              <StyledTableRow key={report.reportId} onClick={() => newTab(report)}>
                <StyledTableCell component="th" scope="row"> {report.alias}
                </StyledTableCell>
                <StyledTableCell align="left">{report.category}</StyledTableCell>
                <StyledTableCell align="left">{report.purpose}</StyledTableCell>
              </StyledTableRow>
            ))
          }
        </TableBody>
        <TableFooter>
          <TableRow sx={{ backgroundColor: '#EEE' }}>
            <StyledTableCell colSpan={3}>
              <ReportNotFound />
            </StyledTableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>) : <></>
  );
}

export default ReportCatalog
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { teal, lightBlue } from '@mui/material/colors';
import CardHeader from '@mui/material/CardHeader'
import { ChatbotResultType, useGetAnswersMutation } from '../store/services/chatservice';
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import Map from '../components/chatbot/Map'
import Report from '../components/chatbot/Report'
import QuestionsDrawer from '../components/chatbot/QuestionsDrawer';
import { useIdToken } from '../hooks/useLogin';
import FaultAlarm from '../components/chatbot/FaultAlarm';
import QuestionInput from '../components/chatbot/QuestionInput';
import ChatHeader from '../components/chatbot/ChatHeader';
import FormatTabularData from '../components/chatbot/FormatTabularData';

const REFRESH_TOKEN_EVERY_X_MINUTES = 20 * 1000 * 60

export interface ChatResponseAnswer {
  response_data: {
    "data": any // ReportProps | string | number,
    "data_type": string,
    "data_unit": string,
    "machine_model": string,
    "machine_vin": string,
    "metric": string,
    "metric_type": string,
    "time": string
  },
  response_type: string
}

interface ChatResultType {
  query: String,
  answer: ChatResponseAnswer[]
}

const ChatPage = () => {
  const { getToken } = useIdToken()
  const [query, setQuery] = useState<string>('Where are my machines?')
  const [chatResults, setChatResults] = useState<ChatResultType[]>([])
  let [getAnswers, { isLoading }] = useGetAnswersMutation<ChatbotResultType>()
  const [openDrawer, setOpenDrawer] = useState(false)
  const email = useSelector((root: RootState) => root.chatbot.email)

  const toggleDrawer = () => setOpenDrawer((prev) => !prev)

  const handleSendClick = async (inputValue: string | null) => {
    setQuery('')
    if (!inputValue) return

    getAnswers({ email, query: inputValue })
      .then((res: any) => setChatResults(p => [...p, { query: inputValue, answer: res.data }]))
      .finally(() => {
        const _questions = localStorage.getItem("questions") || '[]'
        const questions: [string?] = JSON.parse(_questions)
        const _indexFound = questions.findIndex(r => r === inputValue)

        if (_indexFound === -1) questions.push(inputValue)

        localStorage.setItem("questions", JSON.stringify(questions))

        const id = chatResults?.length.toString() || ''

        if (id) {
          setTimeout(() => {
            document.getElementById(id)?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            });
          }, 2000)
        }
      })
  }

  useEffect(() => {
    getToken()
    const timer = setInterval(async () => {
      await getToken()
    }, REFRESH_TOKEN_EVERY_X_MINUTES);

    return () => {
      clearInterval(timer);
    }
  }, [getToken]);

  return (
    <>
      {/* <ChatHeader /> */}
      <QuestionsDrawer open={openDrawer} toggleDrawer={toggleDrawer} />
      <Grid container component={Paper} sx={{ backgroundColor: '#f5f5f5', color: '#fff' }}>
        <Grid item xs={12} height='72vh' sx={{ overflowY: 'auto' }}>
          {chatResults.map((e, index) => (
            <Grid container key={index}>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: '#f5f5f5',
                  color: '#000',
                  textAlign: 'left'
                }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: teal[100] }}>H</Avatar>
                  }
                  title={e.query}
                />
              </Grid>
              <Grid
                item xs={12}
                sx={{
                  backgroundColor: '#f5f5f5',
                  color: '#000',
                  textAlign: 'left'
                }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: lightBlue['500'] }}>AI</Avatar>
                  }
                  title={e.answer[0].response_type === 'text' ? e.answer[0].response_data.data : ''}
                />
                <Grid container key={index}
                  justifyContent="center"
                  alignItems="center">
                  <Grid item xs={11}
                    sx={{
                      backgroundColor: '#f5f5f5', //434654
                      color: '#000',
                      textAlign: 'left',
                      px: 2
                    }}>
                    <div id={index.toString()}>
                      {
                        (['idling_hours',
                          'working_hours', 'fuel_consumption', 'fuel_level', 'area'].includes(e.answer[0].response_data.metric))
                        && <FormatTabularData data={e.answer} /> /*e.answer.map((ans, index) => {
                          return <GenericCard
                            key={index}
                            data={ans.response_data.data.toString()}
                            model={ans.response_data.machine_model}
                            vin={ans.response_data.machine_vin}
                            time={ans.response_data.time}
                            metric={ans.response_data.metric}
                            metric_type={ans.response_data.metric_type}
                            data_unit={ans.response_data.data_unit}
                          />
                        }) */
                      }

                      {
                        (['alarm', 'fault'].includes(e.answer[0].response_data.metric))
                        && e.answer.map((ans, index) => {
                          return <FaultAlarm
                            key={index}
                            model={ans.response_data.machine_model}
                            vin={ans.response_data.machine_vin}
                            metric_type={ans.response_data.metric_type}
                            time={ans.response_data.time}
                            data={ans.response_data.data.toString()}
                          />
                        })
                      }

                      {
                        (e.answer[0].response_data.metric === 'location')
                        && <Map vehicleLocation={e.answer} />
                      }

                      {
                        (e.answer[0].response_type === 'report')
                        && <Report
                          workspaceId="fcf63b01-e021-46c0-bf4e-3636486ec8f0"
                          reportId="e68d396b-4615-41e8-961b-c0bbad646a2a"
                          companyId={e.answer[0].response_data.data?.company_id} />
                      }
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <QuestionInput
          isLoading={isLoading}
          query={query}
          handleSendClick={handleSendClick} />
      </Grid>
    </>
  );
}


export default ChatPage;
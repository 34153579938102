import { red, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    companyRed?: {
      main?: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    companyRed?: {
      main?: string;
    };
  }

  interface PaletteOptions {    
    companyRed: { main: string }
  } 
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    // primary: {
    //   main: '#000',
    // },
    secondary: {
      main: grey[300],
      light: grey[100]
    },
    error: {
      main: red.A400,
    },
    companyRed: {
      main: "#E44D69"
   },
  },
});

export default theme;
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import * as pbi from "powerbi-client";
import Box from '@mui/material/Box';
import { PowerBIEmbed } from 'powerbi-client-react';
import { makeStyles } from '@mui/styles';
import GeneratePdf from './GeneratePdf';
import { UserReportLogType, useReportLogQuery } from '../store/services/reportApi';

const useStyles = makeStyles(() => ({
  reportStyleClass: {
    height: 'calc(100vh - 10rem)'
  }
}))

interface PBIReportProps {
    reportId: any, // untyped function calls cannot accept typed args
    embedUrl: any,
    embeddedToken: string
}

const PBIReport:React.FC<PBIReportProps> = ({ embedUrl, reportId, embeddedToken }) => {
  const [skip, setSkip] = useState(true)
  const [reportLoadingTime, setReportLoadingTime] = useState(0)
  // const token = useSelector((state: RootState) => state.system.token)
  const userId = useSelector((state: RootState) => state.auth.userId)

  useReportLogQuery<UserReportLogType>({
    ReportId: reportId,
    UserId: userId,
    ReportLoadingTime: reportLoadingTime
  }, { skip })

  const initialTime = new Date()

  const [_report, setReport] = useState<any>()
  const styles = useStyles()

  useEffect(() => {
    const repaint = async () => {
      console.log('repaint ... getting token')
      if( _report ) {
        await _report.setAccessToken(embeddedToken)
      }
    }

    repaint()
  }, [embeddedToken, _report])

  return (
    <Box>
      { _report && <GeneratePdf report={_report} reportId={reportId} /> }

      { !!reportId && <PowerBIEmbed
        embedConfig = {{
          type: 'report',
          id: reportId,
          embedUrl,
          accessToken: embeddedToken,
          tokenType: pbi.models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            background: pbi.models.BackgroundType.Transparent,
          }
        }}

        eventHandlers = { 
          new Map([

            ['loaded', function () {
              const MILLI_CONSTANT = 1000
              const loadingTime = ((new Date().getTime()) - initialTime.getTime())/MILLI_CONSTANT
              setReportLoadingTime(loadingTime)
              if (skip) { // skip true
                setSkip(false)
              }
            }],
            ['rendered', function () { setSkip(true) }],
            ['error', function (event) {}]
          ])
        }
          
        cssClassName = { styles.reportStyleClass }

        getEmbeddedComponent = { (embeddedReport: any) => {
          setReport(embeddedReport);
        }}
      />}
    </Box>
  )
}

export default PBIReport
import { useContext } from 'react'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
// import { MsalContext } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

interface AuthState {
  idToken: string,
  userId: string,
  isLoggedIn: boolean
}

const initialState = { 
    idToken: "", 
    userId: "",
    isLoggedIn: false
} as AuthState

export const getToken = createAsyncThunk(
    'user/getToken',
    async () => {
        const { instance, inProgress } = useMsal()// useContext(MsalContext);
        const isAuthenticated = useIsAuthenticated()

        // no account, try to login
        if(!isAuthenticated && inProgress ===  InteractionStatus.None) {
          await instance.loginPopup();
        }
        
        let authResult = await instance.acquireTokenSilent(loginRequest)
        const token = authResult.account?.idToken
        return token
    }
  )

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIdToken(state, action) {
        state.idToken = action.payload
    },
    setIsLoggedIn(state, action) {
        state.isLoggedIn = action.payload
    },
    setUserId(state, action) {
        state.userId = action.payload
    }
   },
  extraReducers: (builder) => {
    builder.addCase(getToken.fulfilled, (state, action) => {
    //   state.idToken = action.payload.
    }).addCase(getToken.pending, (state, action) => {
    //   state.idToken = action.payload.
    }).addCase(getToken.rejected, (state, action) => {
    //   state.idToken = action.payload.
    })
  }
})

export const { setIdToken, setIsLoggedIn, setUserId  } = authSlice.actions
export default authSlice.reducer
import * as React from 'react';
// import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode,
  index: React.Key | null | undefined,
  value: React.Key | null | undefined
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
        {/* <Box style={{display: value == index ? 'inline' : 'none'}}> */}
          {children}
        {/* </Box> */}
    </div>
  );
}
import React from 'react'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

interface QuestionsDrawerProps {
  open: boolean
  toggleDrawer: () => void
}

const QuestionsDrawer: React.FC<QuestionsDrawerProps> = ({ open, toggleDrawer }) => {
  const _questions = localStorage.getItem("questions")
  let questions: [string?] = []
  if (_questions && _questions[0] === "[") {
    questions = JSON.parse(_questions || "[]")
  }

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      PaperProps={{
        sx: {
          width: 300
        }
      }}
    >
      <IconButton aria-label="delete" size='small'
        onClick={() => toggleDrawer()}
        color='info'
        sx={{
          width: '30px',
          alignSelf: 'end'
        }}>
        <KeyboardDoubleArrowLeftIcon />
      </IconButton>
      <Box role="presentation">
        <List>
          {questions.map((text, index: number) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                {/* <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon> */}
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>

    </Drawer>
  )
}

export default QuestionsDrawer
import React from 'react'
// import markerPin from '../../icons/marker-pin.png'
// import Icon from '@mui/material/Icon'
// import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface MarkerProps {
    className?: string, 
    lat: number, 
    lng: number,
    machine_model: string,
    machine_vin: string,
    $hover?: any
}

const Marker: React.FC<MarkerProps> = (props) => {
  const { /*className, lat, lng,*/ machine_model, machine_vin } = props

  const handleClick = () => {
    console.log(`You clicked on ${machine_vin}`);
  };

  return (
    <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">VIN: {machine_vin}</Typography>
            <b>Model:</b><em>{machine_model}</em>
          </React.Fragment>
        }
      >
        <LocationOnIcon  sx={{ color: 'red' }} />
      </HtmlTooltip>
  )
}



export default Marker
import React, { useContext } from "react";
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button';
import { MsalContext } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { setIdToken, setUserId } from '../store/features/authSlice'

const SignInButton = () => {
  const { instance } = useContext(MsalContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogin = async () => {
    console.log('handle login ...')
    navigate('/auth/index')
    // await instance.loginPopup()
    // const response = await instance.acquireTokenPopup(loginRequest)

    // dispatch(setUserId(response.account?.username))
    // dispatch(setIdToken(response.idToken))
  }

  return (
    <Button variant="contained" className="ml-auto" onClick={() => handleLogin()}>Sign in</Button>
  );
}

export default SignInButton
import {
    createApi,
    fetchBaseQuery
  } from "@reduxjs/toolkit/query/react";
//   import { RootState } from '../'

const APIM_SUBSCRIPTION_VALUE = '949f1556f4cf4d969014bece6dcb2005'
const BASE_URL = 'https://eudtuapimgmd01.azure-api.net/mlchatbot/api/v1'

export interface ChatbotQueryParam {
    email: string,
    query: string
}

export interface StringMap {
    [key: string]: string
}

export interface ChatbotResultType {
    data: StringMap[],
    error: any,
    isLoading: boolean
}

export const chatApi = createApi({
    reducerPath: "chatApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: async (headers, { getState }) => {
            // const state = getState() as RootState
            headers.set('Content-Type', `application/json`)
            headers.set('Ocp-Apim-Subscription-Key', `${APIM_SUBSCRIPTION_VALUE}`)
            // headers.set('Authorization', `Bearer ${state.auth.idToken}`)
            return headers
        }
    }),
    tagTypes: ["Chat"],
    endpoints: (builder) => ({
        fetch: builder.query({
            query: (query: ChatbotQueryParam) => ({
                url: `/data`,
                method: 'GET',
                query
            })
        }),
        getAnswers: builder.mutation({
            query: (body: ChatbotQueryParam) => ({
                url: `/data`,
                method: 'POST',
                body
            })
        }),
        })
    })
    
export const {
    useFetchQuery, useGetAnswersMutation
  } = chatApi;
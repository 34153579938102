import React from 'react';
import Typography from '@mui/material/Typography';
import TabularData from './TabularData';
import { ChatResponseAnswer } from '../../pages/ChatPage'

interface FormatTabularDataProps {
  data: ChatResponseAnswer[]
}

const FormatTabularData: React.FC<FormatTabularDataProps> = ({ data: dataProps }) => {
  const _uniqueMetrics = dataProps.filter((hash => obj => !(hash.has(obj.response_data.metric) || hash.add(obj.response_data.metric) && false))(new Set));
  const uniqueMetrics = _uniqueMetrics.map(r => r.response_data.metric)

  return (
    <>
      {
        uniqueMetrics.map(r => {
          const data = dataProps.filter((k => k.response_data.metric === r))
          return (<div key={r} style={{ padding: 0, margin: 0 }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ my: 1 }}
            >
              {r.replace("_", " ").toUpperCase()}
            </Typography>
            <TabularData data={data} /><br />
          </div>)
        })
      }
    </>
  );
}

export default FormatTabularData

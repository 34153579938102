import React from 'react';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/ChatBubble';
import { useLocation, useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux'
import { setActiveReportCatalog } from '../store/features/tabsSlice'

export default function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const isLoggedIn = !isAuthenticated && inProgress === 'none'

  const handleReportCatalogClick = () => {
    dispatch(setActiveReportCatalog(true))
  }

  const handleMailClick = () => {
    var mail = 'mailto:dl-cnhi-data-engineering-report-request@cnhind.com';
    var a = document.createElement('a');
    a.href = mail;
    a.click();
  }

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
      <img src="/cnhi-logo.png" height="75" alt="logo" />
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr:2, textAlign: "left", color: '#a71930', fontWeight: 800 }}
        >
          PTG Data Engineering Analytics
        </Typography>
        { !isLoggedIn && <>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button 
              variant="contained"
              size="large"
              sx={{ ml: '12px', my: '16.5px', mr: '12px', color: 'primary', minWidth: '200px' }} 
              onClick={handleReportCatalogClick}
              startIcon={<HomeIcon />}
              >
              Report Catalog
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "flex-end" }}>
          <Button 
              variant="outlined"
              size="large"
              sx={{ ml: '12px', my: '16.5px', mr: '12px', color: 'primary', minWidth: '200px' }} 
              onClick={handleMailClick}
              >
              Request Training Help
            </Button>
          </Box>
        </>
        }
      </Toolbar>
    </AppBar>
  );
}

import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import { RootState } from '../../store';
import { addFilter, removeFilter } from '../../store/features/filterSlice';

export default function SearchField() {
  const dispatch = useDispatch()
  const [filterValue, setFilterValue] = useState('')
  const filterWords = useSelector((state: RootState )=> state.filterReports.filter)

  const addFilterItem = (words: string) => {
    if (words) {
      dispatch(addFilter(words))
      setFilterValue('')
    }
  }

  const updateFilterValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterValue(e.target.value)
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      addFilterItem(filterValue)
    }
  }

  const handleDelete = (words?: string) => dispatch(removeFilter(words))

  return (
    <Box sx={{ pt: 1, mb: '12px' }}>
      <Typography 
        fontWeight={600}
        fontStyle="normal"
        fontFamily="Open Sans"
        fontSize={14}
        align="left"
        marginBottom={"12px"}
        >
          Filter by Custom Keyword
      </Typography>
      <Paper
        sx={{ display: 'flex', mb: 1 }}
        >
        <InputBase
          sx={{ flex: 1, paddingLeft: 1 }}
          value={filterValue}
          onChange={updateFilterValue}
          onKeyDown={onKeyDown}
          placeholder="Enter a keyword"
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon onClick={() => addFilterItem(filterValue)}/>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <Button variant="contained" size='small' 
                onClick={() => addFilterItem(filterValue)}
                sx={{ 
                  marginTop: 1, 
                  marginBottom: 1,
                  padding: 0,
                  minWidth: '32px'
                }}>
                <ArrowForwardIcon />
              </Button>
            </InputAdornment>
          }
          />
      </Paper>
        {
          filterWords.map(words => (
            <Chip 
              color="primary" 
              label={words} 
              variant="outlined" 
              sx={{ m: 0.5 }} 
              onDelete={() => handleDelete(words)} />
          ))
        }
    </Box>
  );
}
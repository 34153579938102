import * as React from 'react';
import { Outlet } from 'react-router-dom'
// import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../components/Header';
// import LeftMenu from '../components/LeftMenu';
import Tabs from '../components/Tabs';

const XContainer = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <Paper elevation={3}>
        <Header />
      </Paper>
      <Tabs />
      <Grid container spacing={1} sx={{ height: 'calc(100vh - 10em)' }}>
          <Outlet />
      </Grid>
    </Box>
  );
}

export default XContainer
import { configureStore } from "@reduxjs/toolkit";
import {
  useSelector as rawUseSelector,
  TypedUseSelectorHook,
  useDispatch
} from "react-redux";

import authReducer from './features/authSlice'
import systemReducer from "./features/systemSlice";
import tabReducer from './features/tabsSlice'
import filterReducer from './features/filterSlice'
import chatbotReducer from "./features/chatbotSlice";
import { reportApi } from './services/reportApi'
import { chatApi } from './services/chatservice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    system: systemReducer,
    reportTabs: tabReducer,
    filterReports: filterReducer,
    chatbot: chatbotReducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(reportApi.middleware)
    .concat(chatApi.middleware),
  devTools: process.env.NODE_ENV !== 'production'
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;


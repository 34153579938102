import { createSlice } from '@reduxjs/toolkit'

interface SystemState {
  token: string,
  selectedReportId?: string,
  selectedReportEmbedURL?: string,
}

const initialState = { 
    token: "", 
} as SystemState

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setToken(state, action) { 
      state.token = action.payload
    },
    setSelectedReportId(state, action) {
      state.selectedReportId = action.payload
    },
    setSelectedReportEmbedURL(state, action) {
      state.selectedReportEmbedURL = action.payload
    }
  },
})

export const { setToken, setSelectedReportId, setSelectedReportEmbedURL } = systemSlice.actions
export default systemSlice.reducer
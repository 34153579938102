import React, { useState, useEffect, ChangeEvent } from 'react'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions<string>();
interface QuestionInputProps {
  isLoading: boolean,
  query: string,
  handleSendClick: (inputValue: string | null) => void
}

const QuestionInput: React.FC<QuestionInputProps> = ({ isLoading, query, handleSendClick }) => {
  const [value, setValue] = useState<string | null>(query);
  const [questionOptions, setQuestionOptions] = useState<string[]>([]);

  useEffect(() => {
    const loadQuestions = () => {
      const _questions = localStorage.getItem("questions") || '[]'
      setQuestionOptions(JSON.parse(_questions))
    }
    setValue('')
    loadQuestions()
  }, [])

  return (
    <Grid item xs={12} sx={{ backgroundColor: '#f5f5f5', color: '#000' }}>
      <Grid container sx={{ mb: '75px' }} justifyContent="center">
        {isLoading && (<Grid item xs={12} >Loading ...</Grid>)}
        {!isLoading && (<Grid item xs={11} sx={{ px: 2 }}>
          <Autocomplete
            fullWidth
            value={value}
            onChange={(event: any, newValue) => {
              setValue(newValue)
              
              if (event?.key === 'Enter' && newValue?.trim()) {
                const _indexFound = questionOptions.findIndex(r => r === newValue)
                if (_indexFound === -1)
                  setQuestionOptions((v) => [newValue, ...questionOptions])

                handleSendClick(newValue)
                setValue('')
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;

              const isExisting = options.some((option) => inputValue === option);
              if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="question"
            options={questionOptions}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }

              if (option) {
                return option;
              }
              return option;
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={{ mt: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Please type your question"
                sx={{ color: '#fff', backgroundColor: '#fff' }}
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  const newValue = event?.target.value
                  setValue(newValue)
                }}
                onKeyDown={(event: any) => {
                  if (event?.key === 'Enter') {
                    const _indexFound = questionOptions.findIndex(r => r === value)
                    if (_indexFound === -1 && value)
                      setQuestionOptions((v) => [value, ...questionOptions])

                    handleSendClick(value)
                    setValue('')
                  }
                }}
              />
            )}
          />
        </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default QuestionInput
import React from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { resetFilter } from '../../store/features/filterSlice';

const ResetFilters = () => {
  const dispatch = useDispatch()
  const handleClick = () => dispatch(resetFilter())

  return (
    <Box sx={{ pt: 0, ml: 1, textAlign: "left" }} >
      <Button 
        sx={{ 
          ml: 1, 
          mt: 2, 
          padding: '6px 16px 6px 16px', 
          borderRadius: '4px',
          textTransform: 'none'
        }} 
        variant="contained"
        onClick={() => handleClick()}
      >Reset Filters</Button>
    </Box>
  )
}

export default ResetFilters
import { useDispatch } from 'react-redux'
import { useMsal } from "@azure/msal-react"
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser'
import { setIdToken, setUserId } from '../store/features/authSlice'
import { loginRequest } from '../authConfig'

export const useIdToken = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch()

  const getToken = async () => {
    let token: AuthenticationResult
    
    try {
      instance.setActiveAccount(accounts[0])
      token = await instance.acquireTokenSilent(loginRequest)

      dispatch(setUserId(accounts[0].username))
      dispatch(setIdToken(token.idToken))
      return token
    } catch (error: any) {
      if (error instanceof InteractionRequiredAuthError) {
        const response = await instance.acquireTokenPopup(loginRequest);
        dispatch(setUserId(response.account?.username))
        dispatch(setIdToken(response.idToken))
      } else {
        // console.log('error use login: ', error)
      }
    }
    // }
  }
  return { getToken }
}

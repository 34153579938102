import React, { useEffect, useState } from 'react'
import * as pbi from "powerbi-client";
import Box from '@mui/material/Box';
import { PowerBIEmbed } from 'powerbi-client-react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  reportStyleClass: {
    height: 'calc(100vh - 10rem)'
  }
}))

interface ChatPBIReportProps {
    reportId: any, // untyped function calls cannot accept typed args
    embedUrl: any,
    token: string
}

const ChatPBIReport:React.FC<ChatPBIReportProps> = ({ embedUrl, reportId, token }) => {
  const [_report, setReport] = useState<any>()
  const styles = useStyles()

  useEffect(() => {
    const repaint = async () => {
      if( _report ) {
        await _report.setAccessToken(token)
      }
    }

    repaint()
  }, [token, _report])

  return (
    <Box>
      { !!reportId && <PowerBIEmbed
        embedConfig = {{
          type: 'report',
          id: reportId,
          embedUrl,
          accessToken: token,
          tokenType: pbi.models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            background: pbi.models.BackgroundType.Transparent,
          }
        }}

        eventHandlers = { 
          new Map([

            ['loaded', function () {}],
            ['rendered', function () {}],
            ['error', function (event) {}]
          ])
        }
          
        cssClassName = { styles.reportStyleClass }

        getEmbeddedComponent = { (embeddedReport: any) => {
          setReport(embeddedReport);
        }}
      />}
    </Box>
  )
}

export default ChatPBIReport